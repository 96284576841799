import React from "react";
const Sea = ({ size = 28, className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 36 28"
    className={`${className} fill-current`}
  >
    <path d="M34.32 23.907a5.08 5.08 0 00-3.134 1.023 3.595 3.595 0 01-2.323.768h-.553l2.433-8.043a.69.69 0 00-.22-.804l-3.282-2.34V7.528c-.038-1.645-1.364-2.976-3.023-3.034h-3.244V1.935c0-.621-.332-1.097-.959-1.097h-3.981c-.627 0-.959.476-.959 1.097v2.56h-3.244c-1.66.057-2.985 1.388-3.023 3.033v6.983l-3.281 2.34a.764.764 0 00-.258.804l2.47 8.006h-.59a3.558 3.558 0 01-2.286-.767A5.079 5.079 0 001.73 23.87a.734.734 0 00-.738.731c0 .404.33.732.738.732a3.596 3.596 0 012.322.767 4.967 4.967 0 003.097 1.06 5.079 5.079 0 003.134-1.023 3.56 3.56 0 012.285-.768 3.597 3.597 0 012.323.768 4.967 4.967 0 003.097 1.023 5.079 5.079 0 003.133-1.023 3.86 3.86 0 014.609 0 5.308 5.308 0 006.267 0 3.596 3.596 0 012.322-.768.734.734 0 00.738-.731.734.734 0 00-.738-.731zM16.55 2.3h2.95v2.193h-2.95V2.301zm.737 23.324a3.886 3.886 0 01-1.585-.695 5.08 5.08 0 00-3.134-1.023 4.967 4.967 0 00-3.097 1.023l-.331.22-2.286-7.422 10.433-7.311v15.208zm.295-17.22l-7.3 5.083v-5.96a1.652 1.652 0 011.549-1.572h12.387a1.652 1.652 0 011.548 1.572v5.96l-7.262-5.082a.854.854 0 00-.922 0zm8.959 16.525a5.079 5.079 0 00-3.134-1.023 4.967 4.967 0 00-3.097 1.023c-.45.354-.982.593-1.548.695V10.417l7.152 5.008a.368.368 0 00.184.146l3.097 2.157-2.286 7.422-.368-.22z"></path>
  </svg>
);
export default Sea;

import React from "react";
const Price = ({ size = 28, className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 31 29"
    className={`${className} fill-current`}
  >
    <path
      fillRule="evenodd"
      d="M24.09 5.864c-2.086 2.101 1.103 5.266 3.2 3.19 2.068-2.138-1.068-5.248-3.2-3.19zm2.399 2.393c-1.067 1.029-2.635-.526-1.6-1.595 1.048-1.038 2.643.545 1.6 1.595z"
      clipRule="evenodd"
    ></path>
    <path d="M13.781 11.418l.7.68c.08.078.204.09.299.03 1.048-.67 2.229-1.012 3.442-.9 2.334.24 4.671 2.223 5.113 4.581.002.286.278.941-.133 1.021-.433.021-1.058.193-1.404-.155-.342-.455-.365-1.084-.717-1.606-.51-.893-1.693-1.745-2.638-1.8-.267-.094-2.409.176-1.946.787l3.164 3.072a.241.241 0 01.005.342c-.23.327-.719.436-1.021.122l-3.063-2.976a.242.242 0 00-.335 0c-.17.232-.735.497-.477.824l2.698 2.62a.241.241 0 01.005.342c-.229.327-.718.437-1.02.122l-2.264-2.198a.241.241 0 00-.378.053c-.385.681-.548 1.331-.49 1.95.078 1.322 1.522 2.697 2.811 2.953 1.404.233 1.34.383 1.227 1.709-.083.538-1.836.098-2.223-.043-1.89-.693-3.745-2.752-3.882-4.829-.1-1.206.298-2.388.97-3.43a.241.241 0 00-.034-.304l-.665-.646c-.269-.247.261-.57.383-.74.426-.382.802.9 1.227.518.157-.25.733-.487.47-.819l-.55-.534a.241.241 0 01-.004-.342c.17-.127.47-.664.73-.404z"></path>
    <path d="M15.665 29.975a4.26 4.26 0 01-3.016-1.242L4.25 20.358A4.223 4.223 0 013 17.348a4.22 4.22 0 011.251-3.01L15.041 3.58c.742-.763 1.147-1.508 2.473-1.504 15.95.209 13.236-2.649 13.41 13.37-.012 1.285-.749 1.822-1.443 2.52l-10.8 10.767a4.26 4.26 0 01-3.016 1.243zm1.85-26.153C17 3.672 6.17 15.1 5.482 15.574c-.976.934-.977 2.614 0 3.547l8.399 8.375c.983.98 2.583.98 3.566 0 .56-.79 11.858-11.314 11.73-12.049V4.512a.692.692 0 00-.694-.69h-10.97z"></path>
  </svg>
);
export default Price;

"use client";

import { MonitoringEvent } from "@/utils/events";
import { useEffect } from "react";

type Props = {};

const ItemError = ({ __component }: any) => {
  const sendEvent = async () => {
    await MonitoringEvent(__component, "");
  };
  useEffect(() => {
    sendEvent();
  }, []);
  if (process.env.NEXT_PUBLIC_STAGE === "development") {
    return (
      <div className="flex h-auto w-full animate-pulse flex-col items-center justify-center overflow-hidden rounded-lg bg-gradient-to-b from-red-500 to-red-700 p-2 text-white duration-1000 ">
        <span className="flex flex-col items-center justify-center">
          <p className="text-lg font-semibold">Error</p>
          <p>{__component}</p>
        </span>
      </div>
    );
  }
  return <></>;
};

export default ItemError;

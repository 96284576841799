import React from "react";
const Cremation = ({ size = 28, className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={size}
    height={size}
    viewBox="0 0 26 34"
    className={`${className} fill-current`}
  >
    <path d="M25.932 19.372c0-2.97-2.339-5.425-5.336-5.746v-1.43c.776-.266 1.334-.98 1.334-1.818 0-.846-.57-1.568-1.36-1.825-.264-2.724-2.497-4.903-5.31-5.204v-.68c0-1.062-.899-1.927-2.002-1.927-1.103 0-2.001.865-2.001 1.927v.68c-2.814.3-5.047 2.48-5.31 5.204-.79.257-1.36.979-1.36 1.825 0 .838.557 1.552 1.333 1.817v1.431c-2.997.321-5.336 2.775-5.336 5.746 0 3.215 1.949 5.788 3.515 7.855.756.998 1.478 1.951 1.728 2.697-.727.29-1.24.98-1.24 1.783 0 1.062.897 1.927 2 1.927H19.93c1.103 0 2-.865 2-1.927 0-.804-.513-1.494-1.24-1.783.25-.745.972-1.699 1.728-2.697 1.566-2.067 3.515-4.64 3.515-7.855zm-6.67-7.066v1.285H7.255v-1.285h12.007zM13.258 2.027c.368 0 .667.288.667.642v.643h-1.334v-.643c0-.354.3-.642.667-.642zm-6.67 7.709a.655.655 0 00.667-.642c0-2.48 2.094-4.497 4.669-4.497h2.668c2.348 0 4.297 1.678 4.622 3.854h-7.29a.655.655 0 00-.667.643c0 .354.299.642.667.642h8.005c.367 0 .667.288.667.642 0 .355-.3.643-.667.643H6.587a.656.656 0 01-.667-.643c0-.354.3-.642.667-.642zm-4.67 9.636c0-2.48 2.095-4.497 4.67-4.497h13.34c2.575 0 4.67 2.018 4.67 4.497 0 2.796-1.807 5.181-3.259 7.098-.19.252-.377.499-.555.74h-1.005a.655.655 0 00-.667.642c0 .355.3.642.668.642h.132c-.261.442-.46.869-.565 1.285H7.169c-.105-.416-.304-.843-.565-1.285h7.84a.655.655 0 00.666-.642.655.655 0 00-.667-.642h-8.71a77.56 77.56 0 00-.556-.74c-1.452-1.917-3.259-4.302-3.259-7.098zm18.01 12.977H6.589a.656.656 0 01-.668-.642c0-.355.3-.643.667-.643H19.93c.367 0 .667.288.667.643 0 .354-.3.642-.667.642z"></path>
    <path d="M9.26 9.736a.655.655 0 00.666-.642.655.655 0 00-.667-.643.655.655 0 00-.667.643c0 .354.299.642.667.642zM17.11 28.494a.655.655 0 00.668-.642.655.655 0 00-.667-.643.655.655 0 00-.668.643c0 .355.3.642.668.642z"></path>
  </svg>
);
export default Cremation;

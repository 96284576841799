export const scrollToElement = (idElement: string, extraSpace: number = 0) => {
  var element = document.getElementById(idElement.replace("#", ""))!;
  var headerOffset = 100;
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition =
    elementPosition + window.pageYOffset - headerOffset - extraSpace;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

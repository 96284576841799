import React from "react";
const Burial = ({ size = 28, className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 31 29"
    className={`${className} fill-current`}
  >
    <path d="M29.508 20.197v-6.623c.556-.191.957-.698.957-1.298 0-.607-.41-1.119-.977-1.304-.205-2.872-2.69-5.15-5.724-5.15V5.49c0-.54-.31-1.025-.814-1.267a.5.5 0 01-.268-.322l-.275-1.06a1.417 1.417 0 00-.61-.834l-.868-.557a1.506 1.506 0 00-.815-.238h-1.315a.525.525 0 01-.284-.083l-.937-.601a1.506 1.506 0 00-.815-.237h-2.272c-.29 0-.572.082-.815.237l-.937.601a.524.524 0 01-.284.083h-1.314c-.291 0-.573.083-.815.238l-.868.557a1.411 1.411 0 00-.61.835l-.276 1.06a.498.498 0 01-.27.322A1.403 1.403 0 007.49 5.49v.332c-3.033 0-5.518 2.278-5.724 5.15-.566.185-.977.697-.977 1.304 0 .6.402 1.107.957 1.298v6.624c-.555.19-.957.697-.957 1.297 0 .356.144.677.373.922-.229.245-.373.567-.373.922v5.532h29.676v-5.532c0-.355-.144-.677-.373-.922.23-.245.373-.566.373-.922 0-.6-.401-1.107-.957-1.298zm-.957-6.538v6.453H2.704V13.66H28.55zm-.025-2.766h-4.762V6.744c2.478 0 4.522 1.823 4.762 4.149zM8.447 7.666v-.922h11.488v.922H11.32V9.05h-.957V7.666H8.447zm12.445-.922h1.915v3.227l-.957-1.23-.958 1.23V6.744zm.958 3.534l.478.615h-.957l.479-.615zM8.447 5.49a.49.49 0 01.286-.442c.384-.185.664-.522.768-.923l.275-1.06a.494.494 0 01.213-.29l.867-.558a.525.525 0 01.285-.083h1.314c.291 0 .573-.082.815-.237l.937-.602a.528.528 0 01.284-.083h2.272c.102 0 .2.03.284.083l.937.602c.242.155.524.237.815.237h1.315c.101 0 .2.029.284.083l.868.557a.492.492 0 01.213.291l.275 1.06c.104.401.384.738.77.924a.49.49 0 01.283.44v.333H8.447V5.49zM7.49 6.744v.922c0 .508.43.922.957.922h.958v.461c0 .508.43.922.957.922h.957c.528 0 .958-.414.958-.922v-.461h7.658v2.305H2.728c.24-2.326 2.285-4.149 4.762-4.149zm-5.265 5.07h26.804a.47.47 0 01.48.462.47.47 0 01-.48.46H2.226a.47.47 0 01-.479-.46.47.47 0 01.479-.461zm0 9.22h26.804a.47.47 0 01.48.461.47.47 0 01-.48.461H2.226a.47.47 0 01-.479-.46.47.47 0 01.479-.462zm-.479 6.915v-2.305h.958v-.922h-.958v-1.383a.47.47 0 01.479-.46h26.804a.47.47 0 01.48.46v1.383h-.958v.922h.957v2.305H1.746z"></path>
    <path d="M5.581 18.268h1.915c0 .509.43.922.957.922h.958c.527 0 .957-.413.957-.922h3.829c0 .509.43.922.957.922h.958c.528 0 .957-.413.957-.922h3.83c0 .509.428.922.956.922h.958c.528 0 .957-.413.957-.922h1.915c.528 0 .957-.413.957-.922v-.922c0-.508-.43-.921-.957-.921H23.77c0-.509-.43-.922-.957-.922h-.957c-.529 0-.958.413-.958.921H17.07c0-.508-.43-.921-.957-.921h-.958c-.528 0-.957.413-.957.921h-3.83c0-.508-.429-.921-.956-.921h-.958c-.528 0-.957.413-.957.921H5.58c-.528 0-.957.414-.957.922v.922c0 .509.43.922.957.922zm20.104-1.844v.922H23.77v-.922h1.915zm-3.83-.921h.958v2.765h-.957v-2.766zm-.957.921v.922H17.07v-.922h3.83zm-5.744-.921h.958v2.765h-.958v-2.766zm-.957.921v.922h-3.83v-.922h3.83zm-5.744-.921h.958v2.765h-.958v-2.766zm-2.872.921h1.915v.922H5.58v-.922zM11.325 3.056h.958v.922h-.958v-.922zM13.237 3.978h.958V4.9h-.958v-.922zM18.983 3.978h.958V4.9h-.958v-.922zM15.148 2.134h.958v.922h-.958v-.922zM17.059 3.056h.957v.922h-.957v-.922zM3.656 24.722H27.59v.922H3.656v-.922z"></path>
  </svg>
);
export default Burial;

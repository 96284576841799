"use client";
import { ButtonEventsProps } from "@/types/button";
import Cookie from "js-cookie";

declare const window: Window & {
  dataLayer: Record<string, unknown>[];
  mtls?: any;
};

export const ScanMatelso = () => {
  if (window.mtls) {
    // console.log("delay matelso");
    setTimeout(() => {
      // console.log("scan matelso");
      window.mtls.scan();
    }, 250);
  }
};

export const ErrorPageEvent = async (code: string, referer: string = "") => {
  code = code.replace(/\"/g, "'");
  referer = referer.replace(/\"/g, "'");
  await fetch(`${process.env.NEXT_PUBLIC_DOMAIN_ROUTE}/api/pageload/event/`, {
    method: "POST",
    body: JSON.stringify({
      errorMessage: code,
      resourceUrl: getPageUrl(),
      context: `referer:${referer}`,
    }),
  })
    .then((response) => console.log(response))
    .catch((err) => console.log(err));
};

export const MonitoringEvent = async (
  component: string,
  position: string = ""
) => {
  console.log("monitoring event", component, position);
  await fetch(`${process.env.NEXT_PUBLIC_DOMAIN_ROUTE}/api/monitoring/event/`, {
    method: "POST",
    body: JSON.stringify({
      component: component,
      position: position,
      url: getPageUrl(),
    }),
  })
    .then((response) => console.log(response))
    .catch((err) => console.log(err));
};

export const StatsigSendEvent = async (event: string, value: string = "") => {
  let user = Cookie.get("uid");
  let viewport = Cookie.get("viewport")
    ? Cookie.get("viewport")
    : "notDetected";
  let metadata = {
    gclid: Cookie.get("gclid"),
    msclkid: Cookie.get("msclkid"),
    fbclid: Cookie.get("fbclid"),
    country: Cookie.get("country"),
    region: Cookie.get("region"),
    city: Cookie.get("city"),
    pageCanonical: Cookie.get("pageCanonical"),
    offerUUID: Cookie.get("offerUUID"),
    version: "LP",
  };
  await fetch(`https://www.benu.at/api/statsig/event/`, {
    method: "POST",
    body: JSON.stringify({
      user: user,
      viewport: viewport,
      metadata: metadata,
      event: event,
      value: value !== "" ? value : getPageUrl(),
    }),
  });
};

const getPageUrl = (): string => {
  if (window) {
    return `${window.location.pathname}`;
  }
  return "";
};

export const GASendEvent = ({
  eventName,
  eventCategory,
  eventAction,
  eventLabel,
}: ButtonEventsProps) => {
  console.log(
    "SEND EVENT TO ANALYTICS",
    eventName,
    eventCategory,
    eventAction,
    eventLabel
  );
  if (window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
    });
  } else {
    console.log("Analytics not detected");
  }
};

export const GAPageView = (url: string) => {
  console.log("SEND PAGEVIEW TO ANALYTICS", url);

  if (window.dataLayer) {
    window.dataLayer.push({
      event: "pageview",
      page: url,
    });
  }
};

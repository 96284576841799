import React from "react";
const List = ({ size = 28, className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 31 29"
    className={`${className} fill-current`}
  >
    <path
      fillRule="evenodd"
      d="M29.198 4.539l-3.98-2.298c-.804-.464-1.627-.249-2.094.561l-1.215 2.105H5.065C3.384 4.907 2 6.417 2 8.1v18.279a3.046 3.046 0 003.044 3.047h18.268c1.682 0 3.204-1.382 3.204-3.065V12.25l3.243-5.616c.467-.81.241-1.63-.56-2.094V4.54zm-4.214 21.82c0 .842-.832 1.542-1.672 1.542H5.044c-.84 0-1.522-.681-1.522-1.523V8.099c0-.843.701-1.659 1.543-1.659h15.96l-1.73 2.994H8.13a.766.766 0 000 1.533h10.282l-1.77 3.064H8.13a.767.767 0 000 1.532h7.628l-1.142 1.978-.009 1.087H8.129a.767.767 0 000 1.532h6.464l-.025 3.064h-6.44a.767.767 0 000 1.532h7.003c.187 0 .591-.145.76-.23l5.359-3.154 3.734-6.469V26.36zm-4.98-5.895l-4.005 2.338.024-4.637 5.746-9.954 3.982 2.299-5.747 9.953zM28.049 6.53l-1.532 2.654-3.982-2.298 1.533-2.655c.357-.667.409-.676 1.046-.28l2.654 1.532c.648.38.679.363.28 1.047z"
      clipRule="evenodd"
    ></path>
  </svg>
);
export default List;

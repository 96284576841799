"use client";
import { GAEvents } from "@/types/events";
import { IconList } from "@/types/icons";
import { GASendEvent, StatsigSendEvent } from "@/utils/events";
import { getAdjustedPhoneNumber } from "@/utils/phone";
import { scrollToElement } from "@/utils/scroll";
import { getWizardUrl } from "@/utils/wizard";
import { ButtonProps, ButtonSize, ButtonType } from "../types/button";
import Icons from "./Icons";
import ItemError from "./error/ItemError";

const Button = ({
  size = ButtonSize.MEDIUM,
  visual,
  variation,
  extraLabel,
  label,
  icon,
  iconPosition,
  className,
  ariaLabel,
  buttonId,
  type,
  link,
  sendEvent,
  event,
  ...props
}: ButtonProps) => {
  // console.log(type, link);
  let path = "";
  // console.log(link);
  const handlerEvent = async () => {
    if (type === ButtonType.SCROLL && link.startsWith("#")) {
      scrollToElement(link);
    }
    {
      props.onClick && props.onClick();
    }

    if (type === ButtonType.WIZARD) {
      GASendEvent({
        eventName: GAEvents.CTA,
        eventCategory: "Contact Form Funnel",
        eventAction: "start",
        eventLabel: getWizardUrl(link ? link : "", ""),
      });
      await StatsigSendEvent("wizard-start");
    } else if (type === ButtonType.WIZARD_PROVISION) {
      GASendEvent({
        eventName: GAEvents.CTA,
        eventCategory: "Contact Form Funnel",
        eventAction: "start",
        eventLabel: getWizardUrl(link ? link : "", "provision"),
      });
      await StatsigSendEvent("wizard-start");
    } else if (type === ButtonType.PHONE) {
      GASendEvent({
        eventName: GAEvents.PHONE,
        eventCategory: "Phone call",
        eventAction: "start",
        eventLabel: buttonId ? buttonId : "",
      });
      await StatsigSendEvent("phone-call");
    } else if (type === ButtonType.INTERNAL && link.startsWith("tel:")) {
      GASendEvent({
        eventName: GAEvents.PHONE,
        eventCategory: "Phone call",
        eventAction: "start",
        eventLabel: buttonId ? buttonId : "",
      });
      await StatsigSendEvent("phone-call");
    }

    if (buttonId) {
      if (event) {
        GASendEvent(event!);
      }
      await StatsigSendEvent(buttonId!);
    }
    if (type !== ButtonType.PHONE) {
      if (type === ButtonType.WIZARD_PROVISION) {
        window.location.href = getWizardUrl(link, "provision");
      } else if (type === ButtonType.WIZARD) {
        window.location.href = getWizardUrl(link);
      } else {
        if (link.includes(".pdf")) {
          // console.log("download logic");
        } else if (type === ButtonType.EXTERNAL) {
          var isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);
          if (isIOS) {
            window.location.href = link;
          } else {
            const url = link;
            const win = window.open(url);
            if (win) {
              win.focus();
            }
          }
        } else {
          if (type === ButtonType.INTERNAL && !path.startsWith("#")) {
            window.location.href = includeLinkSearchParams(link);
          }
        }
      }
    }
  };

  try {
    if (type === ButtonType.PHONE) {
      path = getAdjustedPhoneNumber().link;
      return (
        <a
          className={`button group ${size} ${
            icon && iconPosition === "right" ? "flex-row-reverse" : ""
          } ${visual ? visual : "primary"} ${variation} ${
            className ? className : ""
          } text-center`}
          data-ci={buttonId}
          id={buttonId}
          {...props}
          aria-label={ariaLabel ? ariaLabel : label}
          href={path}
          target={"_self"}
          onClick={handlerEvent}
        >
          {icon && <Icons icon={icon} />}
          {!extraLabel ? (
            <span>{getAdjustedPhoneNumber().label}</span>
          ) : (
            <span>
              <p>{getAdjustedPhoneNumber().label}</p>
              {extraLabel && <p>{extraLabel}</p>}
            </span>
          )}
        </a>
      );
    } else if (link && link.includes(".pdf")) {
      return (
        <a
          className={`button group ${size} ${
            icon && iconPosition === "right" ? "flex-row-reverse" : ""
          } ${visual ? visual : "primary"} ${variation} ${
            className ? className : ""
          } text-center`}
          data-ci={buttonId}
          id={buttonId}
          {...props}
          aria-label={ariaLabel ? ariaLabel : label}
          href={link}
          download={true}
        >
          {icon && <Icons icon={IconList.DOWNLOAD} />}
          <span>
            <p>{label}</p>
            {extraLabel && <p>{extraLabel}</p>}
          </span>
        </a>
      );
    }
    return (
      <button
        className={`button group ${size} ${
          icon && iconPosition === "right" ? "flex-row-reverse" : ""
        } ${visual ? visual : "primary"} ${variation} ${
          className ? className : ""
        }`}
        data-ci={buttonId}
        id={buttonId}
        {...props}
        aria-label={ariaLabel ? ariaLabel : label}
        onClick={handlerEvent}
      >
        {icon && <Icons icon={icon} />}
        <span>
          <p>{label}</p>
          {extraLabel && <p>{extraLabel}</p>}
        </span>
      </button>
    );

    // throw new Error("invalid")
  } catch (error) {
    return <ItemError __component="button" />;
  }
};

export const includeLinkSearchParams = (_url: string) => {
  if (
    _url.includes("benu.at/") ||
    _url.includes("vercel.app") ||
    _url.startsWith("/") ||
    _url.includes("localhost:3000/")
  ) {
    if (window) {
      if (_url.split("?").length > 1) {
        _url = _url + window.location.search.replace("?", "&");
      } else {
        _url = _url + window.location.search;
      }
    }
  }
  return _url;
};

export default Button;

import React from "react";
const Diamond = ({ size = 28, className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 31 29"
    className={`${className} fill-current`}
  >
    <path d="M16.436 29.199c-.412.02-.655-.402-.59-.754.014-.08 5.362-18.636 5.387-18.718.1-.234.293-.418.58-.418h7.575c.487 0 .783.578.49.968l-9.336 13.308c-.238.15-3.694 6.037-4.106 5.614zm5.865-18.68l-3.649 12.753c-.092.322.34.525.529.248l.384-.564 8.376-11.928a.295.295 0 00-.24-.465l-5.399-.044z"></path>
    <path d="M16.438 29.199a.605.605 0 01-.49-.242c-2.493-3.643-11.04-14.979-13.831-18.68-.294-.39.002-.969.489-.968h7.576c.244 0 .489.145.586.435l6.305 18.68c.146.411-.23.837-.635.775zM4.372 10.519a.296.296 0 00-.235.473l8.88 11.77.408.569c.198.276.63.054.52-.267L9.76 10.72c.103-.446-5.235-.096-5.388-.201z"></path>
    <path d="M16.436 29.198a.614.614 0 01-.587-.435L9.598 10.096c-.144-.389.169-.787.582-.787H21.86c.195 0 .391.096.489.242a.666.666 0 01.098.532l-5.371 18.66a.689.689 0 01-.641.456c.032 0 .032 0 0 0zm-5.41-18.634l5.107 15.08a.295.295 0 00.564-.012l4.273-14.785a.296.296 0 00-.284-.378H11.01c0 .032.006.064.016.095z"></path>
    <path d="M21.864 10.519C7.34 9.854 6.58 13.679 15.168 2.244c.199-.304.68-.298.942-.072.091.042 6.16 7.26 6.234 7.321.392.409-.04 1.031-.48 1.026zm-9.915-1.21h7.96c.252 0 .389-.293.227-.486l-4.192-4.99a.296.296 0 00-.462.013l-3.768 4.99a.296.296 0 00.235.473z"></path>
    <path d="M10.136 10.519c-11.597-.07-8.74 1.427-3.96-8.18.22-.444.895-.355 1.076.049l3.422 7.308a.561.561 0 01-.538.823zM4.14 9.309c6.833-.096 5.208 1.048 2.769-4.856a.296.296 0 00-.525-.019L3.884 8.868a.296.296 0 00.257.441zM29.39 10.519c-11.38-.105-8.695 1.542-4.693-8.18.168-.414.885-.46 1.075-.047L29.927 9.6a.625.625 0 01-.538.919zm-6.132-1.21h4.599a.296.296 0 00.257-.44c-3.487-5.682-1.995-6.415-5.124.018a.295.295 0 00.267.422z"></path>
    <path d="M21.865 10.52a.798.798 0 01-.517-.228l-6.18-7.323a.582.582 0 01.44-.968h9.629c.397-.022.79.484.586.871l-3.42 7.308c-.101.21-.281.358-.539.34zm-4.477-6.823c5.543 5.962 3.304 6.923 6.771-.065a.295.295 0 00-.267-.42h-6.278a.296.296 0 00-.226.485z"></path>
    <path d="M10.133 10.519c-.218.017-.43-.127-.537-.339L6.174 2.872c-.213-.38.13-.897.587-.871h8.944c.486-.001.783.579.489.968l-5.572 7.308a.603.603 0 01-.489.242zM7.887 3.632c3.368 6.924 1.396 5.781 6.19.052a.296.296 0 00-.235-.473H8.154a.296.296 0 00-.267.421z"></path>
  </svg>
);
export default Diamond;

import Burial from "@/assets/icons/funeral/Burial";
import Cremation from "@/assets/icons/funeral/Cremation";
import Diamond from "@/assets/icons/funeral/Diamond";
import Fire from "@/assets/icons/funeral/Fire";
import List from "@/assets/icons/funeral/List";
import Price from "@/assets/icons/funeral/Price";
import Sea from "@/assets/icons/funeral/Sea";
import Tree from "@/assets/icons/funeral/Tree";
import Link from "@/assets/smallIcons/Link";
import TwentyFourPhone from "@/assets/smallIcons/TwentyFourPhone";
import { Download } from "lucide-react";
import dynamic from "next/dynamic";
import { IconList, IconProps } from "../types/icons";
import ItemError from "./error/ItemError";

const ArrowDown = dynamic(() => import("@/assets/smallIcons/ArrowDown"));
const ArrowUp = dynamic(() => import("@/assets/smallIcons/ArrowUp"));
const ArrowLeft = dynamic(() => import("@/assets/smallIcons/ArrowLeft"));
const ArrowRight = dynamic(() => import("@/assets/smallIcons/ArrowRight"));
const Phone = dynamic(() => import("@/assets/smallIcons/Phone"));

const Icons = ({ icon }: IconProps) => {
  try {
    switch (icon) {
      case IconList.ARROW_DOWN:
        return <ArrowDown />;
      case IconList.ARROW_UP:
        return <ArrowUp />;
      case IconList.ARROW_LEFT:
        return <ArrowLeft />;
      case IconList.ARROW:
      case IconList.ARROW_RIGHT:
        return <ArrowRight />;
      case IconList.PHONE:
        return <Phone />;
      case IconList.TWENTY_FOUR:
        return <TwentyFourPhone />;
      case IconList.LINK:
        return <Link />;
      case IconList.CREMATION:
        return <Fire />;
      case IconList.URN_AT_HOME:
      case IconList.URN_AT_HOME_2:
        return <Cremation />;
      case IconList.BURIAL:
        return <Burial />;
      case IconList.SEA_FUNERAL:
      case IconList.SEA_FUNERAL_2:
        return <Sea />;
      case IconList.TREE_FUNERAL:
      case IconList.TREE_FUNERAL_2:
        return <Tree />;
      case IconList.GEMSTONE:
      case IconList.GEMSTONE_2:
        return <Diamond />;
      case IconList.LIST:
        return <List />;
      case IconList.PRICE:
        return <Price />;
      case IconList.DOWNLOAD:
        return <Download />;
      default:
        return <></>;
    }
  } catch (error) {
    return <ItemError __component="Icon Error" />;
  }
};

export default Icons;
